.site_preloader {
    position: fixed;
    height: 100dvh;
    width: 100%;
    z-index: 88888;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    opacity: 1;
}

.site_preloader_fade {
    transition: opacity .3s;
    opacity: 0;
    z-index: -22222;
}

/*
// .block-banner
*/
@import '../variables';
@import '../mixins/preloader';


.block-loader {
    position: relative;
    min-height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block-loader__spinner {
    @include preloader(80px);
}
